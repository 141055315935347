<template>
    <v-container class="w-full mx-auto py-5 py-md-16 pa-6 mt-1 ">
        <v-row class="bg-form rounded-xl d-flex flex-md-row flex-column">
            <v-col md="6" class="px-5 pt-5 pt-md-12 pa-md-12 pb-0 content-image heading">
                <h1 class="my-1 mt-md-4 font-bold text-h5 font-weight-black text-md-h4">{{ $t("reports.title")
                    }}</h1>
                <p class="text-subtitle-2">{{ $t("reports.subtitle")
                    }}</p>
                <img src="../assets/illustrations/reports.svg" srcset="" class="">
            </v-col>
            <v-col md="6" justify="end" class="pa-sm-12 pt-0 pb-5 py-sm-5 py-md-12 px-6 my-auto">
                <v-form class="pa-5 rounded-xl card" ref="form">
                    <v-card-text class="px-2 px-sm-4">
                        <v-row>
                            <v-col cols="12" class="pb-0">
                                <v-text-field ref="harmony" filled shaped :label="$t('reports.form-userHarmony')"
                                    type="text" id="user" v-model="reportForm.harmony" :loading="loadingHarmony" color="#8a72c1"
                                    :rules="harmonyRules" @input="updateHarmonyUrl" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="pb-0">
                                <v-textarea filled shaped :label="$t('reports.form-description')"
                                    v-model="reportForm.message" :loading="loading" color="#8a72c1"
                                    :rules="[rules.required, rules.minMessage]" :maxlength="600" counter rows="3" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="pb-6">
                                <v-file-input filled shaped :loading="loading" :label="$t('reports.form-image')"
                                    v-model="reportForm.file" color="#8a72c1" :rules="[rules.fileSize, rules.fileType]"
                                    accept="image/*" type="file" prepend-icon="mdi-camera" />
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row class="mt-7">
                            <v-col cols="12" sm="6" class="pb-0">
                                <v-text-field filled shaped :loading="loading" :label="$t('reports.form-name')"
                                    v-model="reportForm.first_name" color="#8a72c1"
                                    :rules="[rules.required, rules.min]" />
                            </v-col>
                            <v-col cols="12" sm="6" class="pb-0">
                                <v-text-field filled shaped :loading="loading" :label="$t('reports.form-lastName')"
                                    v-model="reportForm.last_name" color="#8a72c1"
                                    :rules="[rules.required, rules.min]" />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" class="pb-0">
                                <v-text-field filled shaped :loading="loading" :label="$t('reports.form-email')"
                                    type="email" id="email" v-model="reportForm.email" color="#8a72c1"
                                    :rules="[rules.required, rules.email]" />
                            </v-col>
                        </v-row>

                        <v-row>
                            <div class="col-12 col-md-5 pb-0 m-0">
                                <v-autocomplete v-model="reportForm.phone_code" :items="phone_codes" :loading="loading"
                                    item-text="dial_code" item-value="dial_code" color="#8e84c0" filled shaped
                                    :label="$t('reports.form-dialCode')"
                                    class="pr-0 pr-md-1 w-full input-flag pb-3 pb-md-0" :rules="[rules.required]">
                                    <template v-slot:selection="{ item }">
                                        <v-avatar size="18">
                                            <img :src="item.flag" />
                                        </v-avatar>
                                        <span class="mx-2">{{ item.dial_code }}</span>
                                    </template>
                                    <template v-slot:item="{ item }">
                                        <v-avatar size="18">
                                            <img :src="item.flag" />
                                        </v-avatar>
                                        <span class="ml-2">{{ item.dial_code }}</span>
                                    </template>
                                </v-autocomplete>
                            </div>

                            <div class="col-12 col-md-7 py-0 pb-2 py-md-3">
                                <v-text-field id="phone_number" v-model="reportForm.phone_number" filled shaped
                                    :label="$t('reports.form-phone')" type="text" clearable color="#8e84c0"
                                    class="mb-0 pl-0 pl-md-1" :loading="loading"
                                    :rules="[rules.required, rules.numericPhone, rules.minPhone, rules.maxPhone]"></v-text-field>
                            </div>
                        </v-row>


                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn color="#8a72c1" :loading="loading" :disabled="!isFormValid" class="btn-send"
                            @click="registerReport">{{
                                $t("reports.form-btnSend") }}</v-btn>
                    </v-card-actions>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import axios from '@/axios.js';
export default {
    watch: {
        "$i18n.locale": function () {
            this.$refs.form.resetValidation();
        },
    },
    data() {
        return {
            reportForm: {
                harmony: '',
                message: '',
                file: null,
                first_name: '',
                last_name: '',
                email: '',
                phone_code: "(+57)",
                phone_number: '',
            },
            snackbar: {
                show: false,
                message: '',
                type: ''
            },
            loadingSubject: false,
            subject: [],
            loading: false,
            loadingHarmony: false,
            rules: {
                required: value => !!value || this.$t('reports.rule-required'),
                min: value => (value?.length >= 3) || this.$t('reports.rule-min', { min: 3 }),
                max: value => (value?.length <= 40) || this.$t('reports.rule-max', { max: 40 }),
                minMessage: value => (value?.length >= 20) || this.$t('reports.rule-minMessage', { min: 20 }),
                email: value => {
                    const pattern = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
                    return pattern.test(value) || this.$t('reports.rule-email');
                },
                fileSize: v => !v || v.size <= 6 * 1024 * 1024 || this.$t('reports.rule-fileSize'),
                minPhone: v => v.length >= 6 || this.$t('reports.rule-minPhone'),
                maxPhone: v => v.length <= 12 || this.$t('reports.rule-maxPhone'),
                numericPhone: v => /^[0-9]+$/.test(v) || this.$t('reports.rule-numericPhone'),
                fileType: value => {
                    if (!value) return true;
                    const fileType = value.type;
                    const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
                    return validTypes.includes(fileType) || this.$t('reports.rule-fileType');
                },
            },
            phone_codes: null,
            formattedHarmony: '',
            harmonyRules: [
                v => !!v || this.$t('panelHome.data-required'),
                v => v.length >= 3 || this.$t('panelHome.data-minChar'),
                v => v.length <= 20 || this.$t('panelHome.data-maxChar'),
                v => /^(?!.*[.]{2})(?!.*[_]{2})(?!.*[ ]{2})[a-zA-Z0-9._ ]*[a-zA-Z0-9]$/.test(v) || this.$t('panelHome.data-validChars'),
            ],
        };
    },
    created() {
        this.checkLanguage();
    },
    mounted() {
        this.getPhoneCodes();
    },
    methods: {
        checkLanguage() {
            const storedLocale = localStorage.getItem("locale");
            this.lang = storedLocale;
        },
        lang() {
            return this.$i18n.locale;
        },
        async getPhoneCodes() {
            try {
                const response = await axios.get('/get-phone-codes');
                this.phone_codes = response.data.map(code => {
                    code.flag = `https://flagcdn.com/24x18/${code.code.toLowerCase()}.png`;
                    return code;
                });
            } catch (error) {
                console.error(error);
            }
        },
        async updateHarmonyUrl() {
            this.formattedHarmony = this.reportForm.harmony.toLowerCase().replace(/ /g, '');

        },

        async registerReport() {
            const formData = new FormData();
            formData.append('user', this.reportForm.harmony);
            formData.append('slug', this.formattedHarmony);
            formData.append('message', this.reportForm.message);
            formData.append('first_name', this.reportForm.first_name);
            formData.append('last_name', this.reportForm.last_name);
            formData.append('email', this.reportForm.email);
            formData.append('phone_code', this.reportForm.phone_code);
            formData.append('phone_number', this.reportForm.phone_number);
            if (this.reportForm.file) {
                formData.append('file', this.reportForm.file);
            }

            this.loading = true;
            this.loadingHarmony = true;
            try {
                const response = await axios.post('send-report', formData);
                console.log('Response Data:', response.data);

                if (response.data.success) {
                    this.resetForm();
                    this.$vs.notify({
                        title: this.$t('reports.notify-messageSuccessTitle'),
                        text: this.$t('reports.notify-messageSuccessText'),
                        color: 'success',
                        icon: 'check_box',
                        position: 'bottom-center',
                        time: 5000,
                    });
                } else {
                    this.$vs.notify({
                        title: this.$t('reports.notify-messageDangerTitle'),
                        text: this.$t('reports.notify-messageDangerText'),
                        color: 'danger',
                        icon: 'check_box',
                        position: 'bottom-center',
                        time: 5000,
                    });
                }
            } catch (error) {
                this.$vs.notify({
                    title: this.$t('reports.notify-messageDangerTitle'),
                    text: this.$t('reports.notify-messageDangerText'),
                    color: 'danger',
                    icon: 'check_box',
                    position: 'bottom-center',
                    time: 5000,
                });
            } finally {
                this.loading = false;
                this.loadingHarmony = false;
            }
        },

        resetForm() {
            this.reportForm = {
                harmony: '',
                message: '',
                file: null,
                first_name: '',
                last_name: '',
                email: '',
                phone_code: "(+57)",
                phone_number: '',
            };
            this.$refs.form.resetValidation();
        },
    },
    computed: {
        isFormValid() {
            return (
                this.rules.minMessage(this.reportForm.message) === true &&
                this.rules.required(this.reportForm.message) === true &&

                this.rules.fileSize(this.reportForm.file) === true &&
                this.rules.fileType(this.reportForm.file) === true &&

                this.rules.required(this.reportForm.first_name) === true &&
                this.rules.min(this.reportForm.first_name) === true &&

                this.rules.required(this.reportForm.last_name) === true &&
                this.rules.min(this.reportForm.last_name) === true &&

                this.rules.required(this.reportForm.email) === true &&
                this.rules.email(this.reportForm.email) === true &&

                this.rules.required(this.reportForm.phone_number) === true &&
                this.rules.minPhone(this.reportForm.phone_number) === true &&
                this.rules.maxPhone(this.reportForm.phone_number) === true &&
                this.rules.numericPhone(this.reportForm.phone_number) === true


            );
        },
    }

}
</script>
<style scoped>
.card {
    background: white;
    gap: 20px;
    color: rgb(146, 101, 230);
    -webkit-box-shadow: 0px 3px 16px 1px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 3px 16px 1px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 3px 16px 1px rgba(0, 0, 0, 0.15);
}

.btn-send {
    color: white;
}

.bg-form {
    background-color: #978ace2e;

}

.content-image {
    margin: auto;
    height: 100%;
}

.content-image img {
    max-height: 400px;
}

.heading h1 {
    color: #444444;
}

.heading p {
    color: #646464;
    margin-bottom: 0;
}

@media (max-width: 600px) {
    .content-image img {
        max-height: 280px;
    }
}
</style>

<style>
.input-flag .v-select__selections {
    flex-wrap: nowrap !important;
    cursor: pointer;
}

.v-text-field--enclosed .v-input__append-inner {
    margin-top: 7px;
    padding-left: 7px !important;
}
</style>